/* eslint-disable class-methods-use-this */
/* eslint-disable class-methods-use-this */
import { formatDate } from '../utils/date-utils'
import DocumentTableAPI from '../utils/DocumentTableAPI'
import DocumentTableEntity from '../utils/DocumentTableEntity'

export const handleMimeType = (mimeType: string): string => {
  switch (mimeType) {
    case 'application/pdf':
      return 'pdf'
    case 'application/x-pdf':
      return 'pdf'
    case 'image/jpeg':
      return 'jpeg'
    case 'image/jpg':
      return 'jpg'
    case 'application/msword':
      return 'doc'
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx'
    case 'application/vnd.ms-powerpoint':
      return 'ppt'
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx'
    case 'application/vnd.ms-excel':
      return 'xls'
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx'
    default:
      return ''
  }
}

class DocumentTableNormalizer {
  normalize(tableDocument: DocumentTableAPI[]): DocumentTableEntity[] {
    return tableDocument.map((item) => {
      return {
        id: `${item.id};${item.filename}`,
        companyIds: item.companies.map((item) => item.companyId),
        organisationIds: item.organisations.map((item) => item.organisationId),
        filename: item.filename,
        docTypeLabel: item.docType.label,
        docFamilyLabel: item.docType.docFamily.label,
        name: item.name,
        mimeType: handleMimeType(item.mimeType),
        expireAt: item.expireAt
          ? formatDate(item.expireAt, 'DD/MM/YYYY')
          : undefined,
        createdAt: item.createdAt
          ? formatDate(item.createdAt, 'DD/MM/YYYY')
          : undefined,
      }
    })
  }
}

export default new DocumentTableNormalizer()
