import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './myCustomFrenchLocale'

interface MonthRangePickerProps {
  initialstartDate: Date | null
  initialendDate: Date | null
  handleDatesChange: ([startDateMonth, endDateMonth]: [
    Date | null,
    Date | null
  ]) => void
}

const MonthRangePicker: React.FC<MonthRangePickerProps> = ({
  initialstartDate,
  initialendDate,
  handleDatesChange,
}) => {
  const { t } = useTranslation()
  const [startDateMonth, setStartDate] = useState<Date | null>(initialstartDate)
  const [endDateMonth, setEndDate] = useState<Date | null>(initialendDate)
  const onChange = (dates: [Date | null, Date | null]) => {
    const [newStart, newEnd] = dates
    setStartDate(newStart)
    setEndDate(newEnd)
    handleDatesChange(dates)
  }

  return (
    <DatePicker
      selected={startDateMonth}
      onChange={onChange}
      startDate={startDateMonth}
      endDate={endDateMonth}
      showMonthYearPicker
      selectsRange
      dateFormat="MMMM YYYY"
      dateFormatCalendar="MMMM yyyy"
      placeholderText={t('generic.monthPickerPlaceholder')}
      className="datepicker-input"
      calendarClassName="react-datepicker"
      locale="fr-full"
    />
  )
}

MonthRangePicker.defaultProps = {
  initialstartDate: null,
  initialendDate: null,
}

MonthRangePicker.propTypes = {
  initialstartDate: PropTypes.instanceOf(Date),
  initialendDate: PropTypes.instanceOf(Date),
  handleDatesChange: PropTypes.func.isRequired,
}

export default MonthRangePicker
