/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ScreenContainerSimpleLayoutConnexion from '../../layouts/ScreenContainerSimpleLayoutConnexion'
import ErrorBody from './ErrorBody'
import MAINTENANCE_TYPE from '../../utils/maintenance-utils'
import ROUTES from '../../utils/ROUTES'

interface Props {
  title: string
  description: string | JSX.Element
  type: string
  chatbot?: boolean
  textButton?: string
  externalLink?: string
  connected: boolean
}

const ErrorInfo: React.FC<Props> = ({
  title,
  description,
  type,
  chatbot,
  textButton,
  externalLink,
  connected,
}: Props) => {
  const isLogoClickable = type !== 'maintenanceError'

  const [input, setInput] = useState<string[]>([])
  const konamiCodeEnv = process.env.REACT_APP_KONAMI_CODE ?? ''
  const konamiCode = konamiCodeEnv.split(';')

  const history = useHistory()
  const maintenanceMode = localStorage.getItem(
    `maintenanceMode${MAINTENANCE_TYPE.ALL}`
  )
  // if is not maintenance: redirect to the welcome page
  useEffect(() => {
    if (maintenanceMode === 'false' && type === 'maintenanceError') {
      history.push(ROUTES.CheckEmail)
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (maintenanceMode === 'true') {
        setInput((prevInput: string[]) => {
          const newInput = [...prevInput, event.key].slice(-konamiCode.length)
          if (newInput.join('') === konamiCode.join('')) {
            sessionStorage.setItem('konamiAccess', btoa(konamiCodeEnv))
            window.dispatchEvent(
              new CustomEvent('sessionStorageChanged', {
                detail: { key: 'konamiAccess', value: btoa(konamiCodeEnv) },
              })
            )

            return [] // Reset input after success
          }
          return newInput
        })
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [konamiCode])

  return (
    <ScreenContainerSimpleLayoutConnexion
      isLogoClickable={isLogoClickable}
      isLogged={connected}
    >
      <ErrorBody
        title={title}
        description={description}
        type={type}
        chatbot={chatbot}
        textButton={textButton}
        externalLink={externalLink}
      />
    </ScreenContainerSimpleLayoutConnexion>
  )
}

ErrorInfo.defaultProps = {
  chatbot: true,
  textButton: undefined,
  externalLink: undefined,
}

export default ErrorInfo
