enum EnumTypeDocumentWdh {
  FACTURE = 'Facture',
  AVOIR = 'Avoir',
  BRM = 'BRM',
  BI = 'BI',
  BP = 'BP',
  ATTESTATION_VALO = 'attestationValo',
  AUTRES = 'Autres',
}
export default EnumTypeDocumentWdh
