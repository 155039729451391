/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { formatToInputDateWDH } from './date-utils'

interface FilterValues {
  numDemand: string | undefined
  status: StatusOption
  period: {
    startDate: string | null
    endDate: string | null
  }
  customFilters: DynamicFilterValues
}

export interface StatusOption {
  value: string
  label: string
  color?: string
}

export interface DynamicFilterValues {
  [index: string]: string[]
  motif: string[]
  site: string[]
  dossier: string[]
}

export default FilterValues

/**
 * This function is responsble for building the url
 * of the filterBuilder for a giving search,
 * depending on the filter type exp:
 *
 * https://url?startDate[after]=2024-01-01&endDate[before]=2024-01-30&firstName=test
 *
 *
 * @param params    // This should be the intiale url
 * @param filters   // This is the search params from the filterBuilder
 */
export const flatFilter = (
  params: URLSearchParams,
  filters: { [key: string]: any | any[] }
) => {
  if (Object.keys(filters).length > 0) {
    Object.entries(filters).map(([key, value]) => {
      return getFilterType(params, value, key)
    })
  }
}

/**
 * This function returns the formated type of search item exp:
 * startDate[after]=2024-01-01&endDate[before]=2024-01-30
 *
 * @param params    // This should be the intiale url
 * @param item      // This is the filter object
 * @param key       // This is the key param in the url
 */
const getFilterType = (params: URLSearchParams, item: any, key: string) => {
  switch (typeof item) {
    case 'number':
      if (item !== 0) params.append(key, item.toString())
      break
    case 'string':
      if (item !== '') params.append(key, item)
      break
    case 'object':
      if (Array.isArray(item)) {
        if (item.length > 0)
          item.forEach((_item: any) => getFilterType(params, _item, `${key}[]`))
      } else if ('startDate' in item && 'endDate' in item) {
        if (item.startDate !== null && item.endDate !== null) {
          const keyAfter = `${key.split('[]')[0]}[after]`
          const keyBefore = `${key.split('[]')[0]}[before]`
          params.append(
            keyAfter,
            formatToInputDateWDH(item.startDate, 'DD/MM/YYYY')
          )
          params.append(
            keyBefore,
            formatToInputDateWDH(item.endDate, 'DD/MM/YYYY')
          )
        }
      } else if ('startDateMonth' in item && 'endDateMonth' in item) {
        if (item.startDateMonth !== null && item.endDateMonth !== null) {
          const keyAfter = `${key.split('[]')[0]}[after]`
          const keyBefore = `${key.split('[]')[0]}[before]`
          params.append(
            keyAfter,
            formatToInputDateWDH(item.startDateMonth, 'MM/YYYY', 'YYYY-MM')
          )
          params.append(
            keyBefore,
            formatToInputDateWDH(item.endDateMonth, 'MM/YYYY', 'YYYY-MM')
          )
        }
      } else if ('operator' in item && 'value' in item && 'label' in item) {
        let keyOperator = `${key}[${item.operator}]`
        if (item.operator === 'eq') {
          keyOperator = `${key}`
        }
        params.append(keyOperator, item.value)
      } else if ('label' in item && 'value' in item) {
        if (item.value !== 'all') params.append(key, item.value)
      } else {
        throw new Error('Unknown type encountered')
      }
      break
    case 'undefined':
      break
    default:
      throw new Error('Unknown type encountered')
  }
}
