enum MAINTENANCE_TYPE {
  ALL = 'all',
  DEMANDS = 'demands',
  DEMANDS_DETAILS = 'demands_details',
  GED = 'ged',
}

export interface MaintenanceEntity {
  type: string
  label: string
  value: boolean
  message: string | null
  withForm: boolean
}

export enum MAINTENANCE_TAB {
  GENERIC_TEXT = 1,
  GENERIC_TEXT_WITH_FORM = 2,
  PERSONALIZED_TEXT = 3,
}

export default MAINTENANCE_TYPE

export type MaintenanceData = {
  type: string
  label: string
  value: boolean
  message: string | null
  withForm: boolean
}
