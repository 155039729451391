/* eslint-disable @typescript-eslint/no-explicit-any */
import EnumFilterType from '../enums/filters/FilterBuilder'

export interface arrayType {
  [key: string]: string[] | any
}

export interface stringType {
  [key: string]:
    | string
    | { startDate: string | null; endDate: string | null }
    | number
    | any
}
export interface StatusOption {
  value: string
  label: string
  color?: string
  multiple?: boolean
  type?: string
}

export const defaultFilter = {
  label: 'Autres filtres',
  value: 'Autres',
}

interface FilterBase {
  name: string
  type: EnumFilterType
  label: string
  placeholder: string
  apiName: string
  data?: { label: string; value: string; color?: string }[]
}
interface FilterDynamicBase extends FilterBase {
  multiple?: boolean
}

interface FilterItemDatePicker extends FilterDynamicBase {
  type: EnumFilterType.INPUT_DATEPICKER
  multiple?: false // This will enforces multiple to be false
  outsideRange?: boolean
}

interface FilterItemOther extends FilterDynamicBase {
  type: Exclude<EnumFilterType, EnumFilterType.INPUT_DATEPICKER>
  multiple?: boolean
}

export type FilterDynamicItem = FilterItemDatePicker | FilterItemOther

export interface FilterStaticItem extends FilterBase {
  className?: string
  outsideRange?: boolean
}

export interface filterBuilder {
  dynamic: FilterDynamicItem[]
  static: FilterStaticItem[]
}
