import { fr } from 'date-fns/locale/fr'
import { registerLocale } from 'react-datepicker'

const fullMonths = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

const customFr = {
  ...fr,
  localize: {
    ...fr.localize,
    month: (monthIndex: number) => fullMonths[monthIndex],
  },
}

registerLocale('fr-full', customFr)
