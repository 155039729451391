enum EnumFilterType {
  INPUT_STRING = 'string',
  INPUT_NUMBER = 'number',
  INPUT_DATEPICKER = 'date',
  INPUT_MONTH_DATEPICKER = 'date_month',
  INPUT_MONTH_PICKER = 'month',
  INPUT_DROPDOWN = 'array',
  INPUT_WITH_OPERATOR = 'operator',
}
export default EnumFilterType
