/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomSelect from '../Selectors/CustomSelect'

interface Option {
  value: string
  label: string
}

interface Props {
  options: Option[]
  onChange: (value: {
    operator: string
    label: string
    value: string | number
  }) => void // Callback to pass the selected object to the parent
}

const FormDropdownInput: React.FC<Props> = ({ options, onChange }: Props) => {
  const { t } = useTranslation()

  const initialOption = options.length > 0 ? options[0] : null
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    initialOption
  )
  const [inputValue, setInputValue] = useState<string>('')

  // Handle dropdown changes
  const handleOperatorChange = (e: { label: string; value: string }) => {
    setSelectedOption(e)
  }

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  useEffect(() => {
    if (inputValue === '') {
      onChange({
        operator: initialOption?.value ?? '',
        label: initialOption?.label ?? '',
        value: '',
      })
    }
    if (selectedOption && inputValue) {
      onChange({
        operator: selectedOption.value,
        label: selectedOption.label,
        value: inputValue,
      })
    }
  }, [selectedOption, inputValue])

  return (
    <div className="_dropdown-input">
      {/* Dropdown */}
      <CustomSelect
        options={options}
        value={selectedOption}
        className="searchBar_select"
        name="input-1"
        onChange={handleOperatorChange}
        height={47}
      />

      <input
        type="number"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={t('form.enterAmount')}
        className="input-string"
      />
    </div>
  )
}

export default FormDropdownInput
