/* eslint-disable @typescript-eslint/no-explicit-any */
import { isActivity, isBilling } from '../../services/roles-services'
import { ReportKpiEntry } from '../DocumentEntity'
import {
  DocumentWDHTableAPI,
  DocumentWDHTableEntity,
} from '../DocumentTableAPI'
import EnumTypeDocumentWdh from '../enums/MyDocuments/TypeDocumentWdh'

export const getTechId = (item: DocumentWDHTableAPI): string => {
  switch (item.typeDocument) {
    case EnumTypeDocumentWdh.FACTURE:
    case EnumTypeDocumentWdh.BRM:
    case EnumTypeDocumentWdh.AVOIR:
      return `${item.idFacture};${item.typeDocument};${item.numDocument};${
        item.idFacture === null || item.gedId === null
      }`
    case EnumTypeDocumentWdh.BI:
      return `${item.idIntervention};${item.typeDocument};${item.numDocument};${
        item.idIntervention === null || item.gedId === null
      }`
    case EnumTypeDocumentWdh.BP:
      return `${item.idPesee};${item.typeDocument};${item.numDocument};${
        item.idPesee === null || item.gedId === null
      }`
    default:
      return ''
  }
}

export const isDocumentDisabled = (
  document: DocumentWDHTableEntity
): boolean => {
  switch (document.typeDocument) {
    case EnumTypeDocumentWdh.FACTURE:
    case EnumTypeDocumentWdh.BRM:
    case EnumTypeDocumentWdh.AVOIR:
      return document.idFacture === null || document.gedId === null
    case EnumTypeDocumentWdh.BI:
      return document.idIntervention === null || document.gedId === null
    case EnumTypeDocumentWdh.BP:
      return document.idPesee === null || document.gedId === null
    default:
      return true
  }
}

export const isNotBilling = (typeDocument: string): boolean => {
  return [
    EnumTypeDocumentWdh.BI.toString(),
    EnumTypeDocumentWdh.BP.toString(),
    EnumTypeDocumentWdh.ATTESTATION_VALO.toString(),
  ].includes(typeDocument)
}

export const checkCategoriesByRoles = (roleNames: any): string => {
  // RDG: Un rôle activités ne doit voir que les catégories Administratif et Vie du contrat
  // RDG: Un rôle facturation ne doit voir que les catégories Administratif et Contrat
  const roleActivity = isActivity(roleNames)
  const roleBilling = isBilling(roleNames)

  if (!roleActivity && roleBilling) return 'Vie du contrat'
  if (!roleBilling && roleActivity) return 'Contrat'
  return ''
}

// Prepare data for download KPI
export const kpiDownloadDocumentData = (
  reportData: Record<string, ReportKpiEntry>,
  docType: string,
  status: number
) => {
  const updatedReportData = { ...reportData }

  if (!updatedReportData[docType]) {
    updatedReportData[docType] = { success: 0, unfound: 0, failure: 0 }
  }

  if (status === 200) {
    updatedReportData[docType].success += 1
  } else if ([404, 204].includes(status)) {
    updatedReportData[docType].unfound += 1
  } else {
    updatedReportData[docType].failure += 1
  }

  return updatedReportData
}
