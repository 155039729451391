/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import EnumFilterType from '../../utils/enums/filters/FilterBuilder'
import SvgIcon from '../Icons/SvgIcon'
import { arrayType } from '../../utils/interfaces/FilterBuilderInterface'
import { subtractDay } from '../../utils/date-utils'

interface Props {
  fixedHeight: boolean
  filterOptions: any
  filterValues: arrayType
  setFiltersValues: React.Dispatch<React.SetStateAction<arrayType>>
  resetFilter: () => void
  setIsSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>
}
const Tags: React.FC<Props> = ({
  fixedHeight,
  filterOptions,
  filterValues,
  setFiltersValues,
  resetFilter,
  setIsSubmitDisabled,
}: Props) => {
  const formatTag = (type: EnumFilterType, filterValue: any): string => {
    switch (type) {
      case EnumFilterType.INPUT_STRING:
      case EnumFilterType.INPUT_NUMBER:
        return filterValue
      case EnumFilterType.INPUT_DATEPICKER:
        return `${filterValue.startDate} - ${subtractDay(
          filterValue.endDate,
          1
        )}`
      case EnumFilterType.INPUT_MONTH_DATEPICKER:
        return `${filterValue.startDateMonth} - ${filterValue.endDateMonth}`
      case EnumFilterType.INPUT_WITH_OPERATOR:
        return `${filterValue.label} ${filterValue.value}`
      case EnumFilterType.INPUT_DROPDOWN:
        return filterValue.label
      default:
        return ''
    }
  }

  const removeTag = (filterName: string, filterValue: string) => {
    let isTheLastTag = false
    setFiltersValues((oldValues: arrayType) => {
      const newValues = {
        ...oldValues,
        [filterName]: oldValues[filterName].filter(
          (current: string) => current !== filterValue
        ),
      }

      isTheLastTag = !Object.values(newValues).some(
        (filter: string[]) => filter.length > 0
      )

      return newValues
    })

    if (isTheLastTag) {
      setIsSubmitDisabled(true)
      resetFilter()
    }
  }

  return (
    <div id="filter-tags" className="filter_module_tags_container">
      <div
        className={`filter_module_tags 
            ${fixedHeight && 'filter_module_tags_fixed-height'}`}
      >
        {filterOptions
          .map((option: any) => {
            const { value, type, label } = option
            return (
              filterValues[value].length > 0 && [
                <span key={value} className="tag_block_name">
                  <div>{label}</div>
                </span>,
                <div key={`${value}.values`} className="tag_block_values">
                  {filterValues[value].map((filterValue: any) => {
                    const displayValue = formatTag(type, filterValue)
                    return (
                      <div
                        key={displayValue}
                        className="button__secondary tag_block_values_item"
                      >
                        {displayValue}
                        <span
                          className="search-selected__close"
                          onClick={() => {
                            removeTag(value, filterValue)
                          }}
                        >
                          <SvgIcon
                            name="close"
                            className="search-selected__close-icon"
                          />
                        </span>
                      </div>
                    )
                  })}
                </div>,
              ]
            )
          })
          .flat()}
      </div>
    </div>
  )
}

export default Tags
