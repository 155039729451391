import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'

import { AppContextProvider } from './store/context'
import App from './App'
import IEisNotAllowed from './pages/IEisNotAllowed'
import * as serviceWorker from './serviceWorker'

import './assets/sass/main.scss'

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_AD_CLIENT_ID}`,
    authority: `${process.env.REACT_APP_AZURE_AD_URL_AUTH}`,
    redirectUri: `${process.env.REACT_APP_AZURE_AD_REDIRECT_URI}`,
  },
}

const pca = new PublicClientApplication(configuration)

const AppInput = () => {
  // @ts-ignore
  const isIE = window.document.documentMode
  if (isIE) {
    return (
      <Suspense fallback={<>...</>}>
        <IEisNotAllowed />
      </Suspense>
    )
  }
  return (
    <AppContextProvider>
      <div className="app">
        <Router>
          <MsalProvider instance={pca}>
            <App />
          </MsalProvider>
        </Router>
      </div>
    </AppContextProvider>
  )
}

ReactDOM.render(<AppInput />, document.getElementById('root'))

serviceWorker.unregister()
