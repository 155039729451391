import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Cookies from 'universal-cookie'

import PropTypes from 'prop-types'
import ROUTES from '../../utils/ROUTES'

import handleNavigation from '../../utils/RedirectionUtils'

import { AppContext } from '../../store/context'
import MAINTENANCE_TYPE from '../../utils/maintenance-utils'

const IsEligibleToAccessNoAuthPages: React.FC = ({ children }) => {
  const history = useHistory()
  const location = useLocation()

  const { state } = React.useContext(AppContext)
  const maintenanceMode = localStorage.getItem(
    `maintenanceMode${MAINTENANCE_TYPE.ALL}`
  )
  const cookies = new Cookies()

  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token')
    const roles = cookies.get('current_user_roles')

    // The user is probably still connected. Let AutoRefreshToken do his job.
    if (refreshToken && roles) {
      handleNavigation(roles, history)
    } else if (
      state.user.email === '' &&
      !refreshToken &&
      location.pathname === ROUTES.Login
    ) {
      history.push(ROUTES.CheckEmail)
    }
  }, [state.user])

  useEffect(() => {
    if (
      (Number(`${process.env.REACT_APP_MAINTENANCE}`) === 1 ||
        maintenanceMode === 'true') &&
      ![ROUTES.Home, ROUTES.CheckEmail, ROUTES.Login].includes(
        location.pathname
      )
    ) {
      sessionStorage.removeItem('konamiAccess')
    }
  }, [location.pathname])

  return <> {children} </>
}

IsEligibleToAccessNoAuthPages.propTypes = {
  children: PropTypes.node.isRequired,
}

export default IsEligibleToAccessNoAuthPages
