import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button as ButtonBootstrap } from 'react-bootstrap'
import logo from '../../assets/logo.png'
import {
  hasAccessTo,
  roleNamesToNumber,
  userAdminLandingPage,
  canManageDocuement,
  userSuperAdminLandingPage,
} from '../../services/roles-services'

import { AppContext } from '../../store/context'
import MenuItem from './MenuItem'
import MenuSubItem from '../../utils/MenuSubItem'
import SvgIcon from '../Icons/SvgIcon'
import ROUTES from '../../utils/ROUTES'
import ROLES from '../../utils/ROLES'
import ROLES_ACCESS from '../../utils/ROLES_ACCESS'
import { isSubMenu } from '../../services/menu-services'
import SubMenu from './SubMenu'
import MainMenu from './MainMenu'
import { hasAccessToManageServices } from '../../services/user-services-services'
import NotificationMenu from './NotificationMenu'
import ButtonExitImpersonnation from '../Buttons/ButtonExitImpersonnation'

function Menu() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const {
    state,
    state: {
      user,
      impersonnation: { isImpersonnation },
      institutionPerimeter,
    },
  } = React.useContext(AppContext)
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [itemCollapsed, setItemCollapsed] = useState('')
  const handleitemCollapsed = (id: string) => {
    if (itemCollapsed === id) {
      setItemCollapsed('')
    } else {
      setItemCollapsed(id)
    }
  }

  useEffect(() => {
    setSideBarOpen(false)
  }, [pathname])

  const { firstname, roleNames, isInternal } = state.user

  const currentRoles = roleNamesToNumber(roleNames)

  const hasAccessToServices = hasAccessToManageServices(
    isInternal,
    institutionPerimeter
  )

  const activitiesMenuItems: Array<MenuSubItem> = [
    { title: t('menu.menu1.subtitle1'), link: ROUTES.ActivitySynthesis },
    { title: t('menu.menu1.subtitle2'), link: ROUTES.ActivityAnalyseEvolution },
    { title: t('menu.menu1.subtitle3'), link: ROUTES.WasteRegister },
  ]
  const billingMenuItems: Array<MenuSubItem> = [
    { title: t('menu.menu2.subtitle1'), link: ROUTES.Billing },
    { title: t('menu.menu2.subtitle2'), link: ROUTES.BillingSynthesis },
    { title: t('menu.menu2.subtitle3'), link: ROUTES.BillingAnalyseEvolution },
  ]
  const userMenuItems: Array<MenuSubItem> = [
    {
      title: [
        ROLES.ROLE_INT_SUPER_ADMIN,
        ROLES.ROLE_INT_SUPPORT_N1,
      ].some((role) => currentRoles.includes(role))
        ? t('menu.menu3.subtitle1_bis')
        : t('menu.menu3.subtitle1'),
      link: ROUTES.Profile,
    },
  ]
  if (hasAccessTo(ROLES_ACCESS.adminUsersRoles, currentRoles)) {
    userMenuItems.push({
      title: t('menu.menu3.subtitle6'),
      link: ROUTES.UsersManagement,
    })
  }
  if (hasAccessTo(ROLES_ACCESS.superAdminPageRole, currentRoles)) {
    userMenuItems.push({
      title: t('menu.menu3.subtitle9'),
      link: ROUTES.SuperAdminUsersManagement,
    })
  }
  if (
    hasAccessTo(ROLES_ACCESS.superAdminPageRole, currentRoles) &&
    user?.isSuperAdminTech
  ) {
    userMenuItems.push({
      title: t('menu.menu3.subtitle10'),
      link: ROUTES.SuperAdminTechMaintenance,
    })
  }
  if (canManageDocuement(roleNames, state.user.accessDocs)) {
    // START To remove after activation in PROD
    const ACCESS_NEW_DOCUMENTS = Boolean(
      parseInt(process?.env?.REACT_APP_ACCESS_NEW_DOCUMENTS || '0', 10)
    )
    // END To remove after activation in PROD

    userMenuItems.push({
      title: t('menu.menu3.subtitle4'),
      link: ACCESS_NEW_DOCUMENTS
        ? ROUTES.DocumentsOthers
        : ROUTES.DocumentsManagement,
    })
  }

  if (hasAccessToServices) {
    userMenuItems.push({
      title: t('menu.menu3.subtitle8'),
      link: ROUTES.MyServices,
    })
  }

  userMenuItems.push({
    title: t('menu.menu3.subtitle7'),
    link: ROUTES.ContactUs,
  })
  userMenuItems.push({
    title: t('menu.menu3.subtitle5'),
    link: ROUTES.CheckEmail,
  })

  const menuClassname = sideBarOpen ? 'menu menu--opened' : 'menu menu--closed'

  const handleNavigationMenu = (rolesNames: string[]) => {
    // tarnslate roles to numbers
    const userManager = userAdminLandingPage(rolesNames)
    const superAdmin = userSuperAdminLandingPage(roleNames)

    if (userManager && !isImpersonnation) {
      return ROUTES.UsersManagement
    }
    if (superAdmin && !isImpersonnation) {
      return ROUTES.SuperAdminUsersManagement
    }
    return ROUTES.Dashboard
  }

  return (
    <>
      <div className="menu__burger">
        <ButtonBootstrap
          variant="transparent"
          onClick={() => setSideBarOpen(!sideBarOpen)}
        >
          <SvgIcon name="menu" className="menu-icon" />
        </ButtonBootstrap>
        <MenuItem
          title={
            <>
              <img alt="logo Suez" src={logo} className="menu__logo" />
            </>
          }
          link={handleNavigationMenu(state.user.roleNames)}
        />
      </div>
      <div className={menuClassname}>
        <MenuItem
          title={
            <>
              <img alt="logo Suez" src={logo} className="menu__logo" />
            </>
          }
          link={handleNavigationMenu(state.user.roleNames)}
        />
        {isImpersonnation && (
          <ButtonExitImpersonnation
            textButton={t('menu.exitImpersonnation')}
            className="menu__impersonnationExit"
            showIcon={true.valueOf()}
          />
        )}

        {isSubMenu(pathname) ? (
          <SubMenu />
        ) : (
          <MainMenu
            currentRoles={currentRoles}
            itemCollapsed={itemCollapsed}
            onItemCollapsed={handleitemCollapsed}
            activitiesMenuItems={activitiesMenuItems}
            billingMenuItems={billingMenuItems}
          />
        )}
        {!isImpersonnation && <NotificationMenu />}

        {!isImpersonnation ? (
          <MenuItem
            title={
              <>
                <SvgIcon name="bonhomme" className="profile-picture" />{' '}
                {firstname}
              </>
            }
            subItems={userMenuItems}
            itemId="user"
            isOpen={itemCollapsed === 'user'}
            onClick={handleitemCollapsed}
            className="menu__user"
          />
        ) : (
          <MenuItem
            title={
              <>
                <SvgIcon name="bonhomme" className="profile-picture" />{' '}
                {firstname}
              </>
            }
            className="impersonnation"
          />
        )}
      </div>
    </>
  )
}

export default Menu
