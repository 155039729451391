/* eslint-disable import/prefer-default-export */

export function formatAmount(
  amount: number,
  currency: string,
  currencySeparator: string,
  decimal = 2
): string {
  if (amount === 0) {
    return `0${currency}`
  }
  if (amount % 1 !== 0) {
    return `${Number(amount).toFixed(decimal)}${currency}`.replace(
      '.',
      currencySeparator
    )
  }
  return `${Number(amount)}${currency}`
}

export const toEuro = (number: number, minimumFractionDigits = 0) => {
  if (number % 1 !== 0) {
    return Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 2,
      minimumFractionDigits,
    }).format(number)
  }
  return Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: minimumFractionDigits,
    minimumFractionDigits,
  }).format(number)
}

export function formatNumber(number: number) {
  if (number % 1 !== 0) {
    return new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number)
  }
  return new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number)
}

export function formatNumberWithNoDigit(number: number) {
  return new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number)
}
