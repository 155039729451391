/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useEffect, useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  useTable,
  useSortBy,
  useFilters,
  useRowSelect,
  usePagination,
  actions,
} from 'react-table'
import { useTranslation } from 'react-i18next'
import SvgIcon from '../Icons/SvgIcon'
import TableColumnFilter from './TableColumnFilter'
import TablePagination from './TablePagination'
import Loading from '../Loaders/Loading'

/**
 * We use react-table lib because the native Suez Design System
 * Component (SDS) does not provide the functionalities we want
 * The react-table lib is cool, but with TYPESCRIPT, we have
 * a lot of problem, so we use JS files
 */
function Table({
  type,
  columns,
  data,
  disabledList = [],
  disabledColumnActionsList = [],
  paginationDisplayed = true,
  // These are wrong
  checkedList = [],
  onSelectedItemChanged = (id) => {},
  onCheckAll = (itemIds) => {},
  // Use this instead:
  onSelectionChange = null,
  onPageChange = (page) => {},
  pageCount = 0,
  headerVisible = true,
  resultPerPage = `${process.env.REACT_APP_TABLE_DEFAULT_RESULT_PER_PAGE}`,
  onSortTableChange = (order) => {},
  initialFilters = [],
  onFiltersChange = (filters) => {},
  nbPerPage = 10,
  onNbPerPageChange = (pageSize) => {},
  isLoading = false,
  isInternalPagination = false,
  initialSortBy = [],
  resetOrgaInsti = false,
  forceReset = false,
  currentPage = 0,
  manualPagination = true,
  autoResetPage = true,
  embeddedFilter = false,
  autoResetSort = true,
  noDataAvailable = '',
  withFilters = true,
  customClass = '',
}) {
  // This useState to disable Sorting when loading table's data
  const [disableSortBy, setDisableSortBy] = useState(false)

  const defaultColumn = useMemo(
    () => ({
      Filter: TableColumnFilter,
    }),
    []
  )

  let useSwitchStateManagement = (state) => ({ ...state })
  if (!onSelectionChange) {
    // TODO remove this and improve checkbox selection everywhere using the state selectedRowIds instead...
    const useStateMemo = (state) => {
      return useMemo(
        () => ({
          ...state,
          selectedRowIds: { ...checkedArrayToObject(checkedList) },
        }),
        [checkedList]
      )
    }
    // TODO remove this and improve checkbox selection everywhere using the state selectedRowIds instead...
    useSwitchStateManagement = (state) => {
      const memoValue = useStateMemo(state)
      return paginationDisplayed
        ? {
            ...state,
            selectedRowIds: { ...checkedArrayToObject(checkedList) },
          }
        : memoValue
    }
  }

  const { t } = useTranslation()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    setAllFilters,
    dispatch,
    rows,
    state: { pageIndex, pageSize, sortBy, filters, selectedRowIds },
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      autoResetSelectedRows: forceReset,
      manualPagination,
      manualSortBy: !embeddedFilter,
      pageCount,
      autoResetPage,
      autoResetFilters: false,
      autoResetSortBy: autoResetSort,
      defaultFilters: embeddedFilter,
      manualFilters: !embeddedFilter,
      disableSortBy,
      getRowId: useCallback((row) => row.id, []),
      useControlledState: (state) => useSwitchStateManagement(state),
      initialState: {
        filters: initialFilters,
        pageSize: nbPerPage,
        sortBy: initialSortBy,
        pageIndex: currentPage,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsData) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="ml-2">
              {onSelectionChange ? (
                <IndeterminateCheckbox
                  isHeader
                  type={type}
                  {...getToggleAllRowsSelectedProps()}
                />
              ) : (
                <IndeterminateCheckbox
                  isHeader
                  type={type}
                  {...getToggleAllRowsSelectedProps()}
                  // TODO remove this and improve checkbox selection everywhere using the state selectedRowIds instead...
                  onChange={() => onCheckAll(page.map(({ id }) => id))}
                />
              )}
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              {onSelectionChange ? (
                <IndeterminateCheckbox
                  type={type}
                  {...row.getToggleRowSelectedProps()}
                  disabled={disabledList?.includes(row.id)}
                />
              ) : (
                <IndeterminateCheckbox
                  type={type}
                  disabled={disabledList?.includes(row.id)}
                  {...row.getToggleRowSelectedProps()}
                  // TODO remove this and improve checkbox selection everywhere using the state selectedRowIds instead...
                  onChange={() => onSelectedItemChanged(row.id)}
                />
              )}
            </div>
          ),
        },
        ...columnsData,
      ])
    }
  )

  useEffect(() => {
    if (!isInternalPagination) {
      dispatch({ type: 'resetSelectedRows' })
      dispatch({ type: 'resetSortBy' })
      setAllFilters(initialFilters)
      onPageChange(1)
    }
  }, [columns])

  useEffect(() => {
    dispatch({ type: actions.resetPage })
  }, [resetOrgaInsti])

  useEffect(() => {
    if (manualPagination) onPageChange(pageIndex + 1)
    else onPageChange(pageIndex)
  }, [pageIndex])

  useEffect(() => {
    onNbPerPageChange(pageSize)
  }, [pageSize])

  useEffect(() => {
    if (embeddedFilter) onPageChange(0)
    onFiltersChange(filters)
  }, [filters])

  useEffect(() => {
    if (onSelectionChange) onSelectionChange(selectedRowIds)
  }, [selectedRowIds])

  useEffect(() => {
    if (sortBy.length === 0) {
      return
    }

    const sorted = {}

    allColumns.forEach((column) => {
      const index = sortBy.findIndex((sort) => sort.id === column.id)
      if (index !== -1) {
        sorted[column.Header] = sortBy[index].desc ? 'desc' : 'asc'
      }
    })
    onSortTableChange(sorted)
    if (embeddedFilter) onPageChange(0)
  }, [sortBy])

  useEffect(() => {
    /** this useEffect to center the loader inside table */
    setDisableSortBy(isLoading)
    const tbody = document.querySelector('tbody')
    const thead = document.querySelector('thead')
    const loader = document.querySelector('table .loading')

    // If the tbody contains more than 10 rows, we center the loader only on the first 10 rows
    let tbodyHeight = tbody?.offsetHeight < 592 ? tbody?.offsetHeight : 592
    const theadHeight = thead?.offsetHeight

    // First render (tbody is null)
    if (tbodyHeight === 0) tbodyHeight = 50

    // We take half of the found height of the tbody + the height of the thead
    if (loader) loader.style.top = `${tbodyHeight / 2 + theadHeight}px`
  }, [isLoading])

  return (
    <div
      className={`table ${
        customClass === ''
          ? rows.length > 6
            ? ''
            : 'table__minHeight'
          : customClass
      }`}
    >
      <table {...getTableProps()}>
        <TableHeader
          headerGroups={headerGroups}
          toDisplay={headerVisible}
          disabledColumnActionsList={disabledColumnActionsList}
          isLoading={isLoading}
          withFilters={withFilters}
        />
        <tbody
          {...getTableBodyProps()}
          className={isLoading ? 'bodyLoadOn' : 'bodyLoadOff'}
        >
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className={withFilters ? '' : 'table__body-row-kpi'}
              >
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
        {isLoading &&
          (page.length === 0 ? (
            // First render
            <tbody>
              <tr>
                <td className="firstRenderLoad">
                  <Loading isLoading />
                </td>
              </tr>
            </tbody>
          ) : (
            // Other render
            <Loading isLoading />
          ))}
      </table>
      {page.length === 0 && !isLoading && (
        <div className="table__caption">{t(noDataAvailable)}</div>
      )}
      {paginationDisplayed ? (
        <TablePagination
          pageIndex={pageIndex}
          // is internal
          pageCount={
            embeddedFilter ? Math.ceil(rows.length / pageSize) : pageCount
          }
          pageSize={pageSize}
          pageOptions={pageOptions}
          resultPerPage={resultPerPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
        />
      ) : null}
    </div>
  )
}

function TableHeader({
  toDisplay,
  headerGroups,
  disabledColumnActionsList,
  isLoading,
  withFilters,
}) {
  if (toDisplay === false) {
    return null
  }

  const foundDisabled = (index) => {
    const colName = headerGroups?.[0]?.headers?.[index]?.Header
    return disabledColumnActionsList.includes(colName)
  }

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, index) => (
            <th className={column.id} {...column.getHeaderProps()}>
              <div
                className={`table__header-cell-container${
                  ['periode', 'createdAt', 'lastConnexionDate'].includes(
                    column.id
                  )
                    ? ' is-date-filter'
                    : ''
                }`}
              >
                <div
                  className={
                    withFilters
                      ? 'table__header-cell'
                      : 'table__header-cell-kpi'
                  }
                >
                  {column.render('Header')}

                  {!foundDisabled(index) && column.canSort ? (
                    <>
                      {/* SORT UI */}
                      <span
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({ title: '' })
                        )}
                        className="table__sort"
                      >
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SvgIcon
                              name="arrowDown"
                              className="table__sort-icon"
                            />
                          ) : (
                            <SvgIcon
                              name="arrowUp"
                              className="table__sort-icon"
                            />
                          )
                        ) : (
                          <SvgIcon
                            name="arrowDown"
                            className="table__sort-icon table__sort-icon--inactive"
                          />
                        )}
                      </span>
                    </>
                  ) : null}

                  {index < headerGroup.headers.length - 1 &&
                  column.Header !== '' ? (
                    <span className="table__separation">&nbsp;</span>
                  ) : (
                    ''
                  )}
                </div>

                {withFilters && (
                  <div className="table__filter">
                    {!foundDisabled(index) && column.canFilter ? (
                      <>
                        {/* FILTER UI */}
                        {column.render('Filter')}
                      </>
                    ) : null}
                  </div>
                )}
              </div>{' '}
              {/* end table header container */}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  )
}

function checkedArrayToObject(checkedArray) {
  return checkedArray.reduce((acc, curr) => {
    acc[curr] = true
    return acc
  }, {})
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, type, isHeader, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    if (type === 'radio')
      return (
        <div className="pretty p-round p-default">
          <input type="radio" ref={resolvedRef} {...rest} />
          <div className="state">
            <label>&#8203;</label>
          </div>
        </div>
      )
    if (type === 'hidden')
      return (
        <input type="hidden" className="checkbox" ref={resolvedRef} {...rest} />
      )
    if (type === 'checkbox' && isHeader)
      return (
        <label className="check-custom-container">
          <input
            type="checkbox"
            className="checkbox header-checkbox"
            ref={resolvedRef}
            {...rest}
          />
          <span className="checkmark">
            <SvgIcon name="check" className="svg svg-icon" />
          </span>
        </label>
      )
    rest.title = 'Sélectionner cette ligne'
    return (
      <div className="pretty p-svg">
        <input
          type="checkbox"
          className="checkbox"
          ref={resolvedRef}
          {...rest}
        />
        <div className="state">
          <SvgIcon name="check" className="svg svg-icon" />
          <label>&#8203;</label>
        </div>
      </div>
    )
  }
)

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  onSelectedItemChanged: PropTypes.func,
  initialFilters: PropTypes.arrayOf(PropTypes.shape({})),
  pageCount: PropTypes.number,
  noDataAvailable: PropTypes.string,
  customClass: PropTypes.string,
}

Table.defaultProps = {
  columns: [],
  data: [],
  type: 'checkbox',
  onSelectedItemChanged: () => {
    // empty just for the compilation
  },
  pageCount: 0,
  initialFilters: [],
  noDataAvailable: 'table.noDataAvailable.default',
  customClass: '',
}

export default Table
